import React, { useState, useRef } from 'react';

import ReactNotification from 'react-notifications-component';

import NotifyContext from 'context/notify_context';
import TitleContext from 'context/title_context';

import { Router, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from 'app';
import { store } from 'react-notifications-component';

export const history = createBrowserHistory();

function MasterComponent() {

  const [ title, setTitle ] = useState('');

  const addNotification = (type, message = 'Ошибка сервера.') => {
    store.addNotification({
      title: type,
      message: message,
      type: type,
      insert: 'top',
      container: 'top-right',
      animationIn: [ 'animated', 'fadeIn' ],
      animationOut: [ 'animated', 'fadeOut' ],
      dismiss: { duration: 2000, onScreen: true },
      dismissable: { click: true }
    });
  };

  return (
    <>
      <ReactNotification />
      <TitleContext.Provider
        value={ {
          title: title,
          setTitle: setTitle,
        } }
      >
        <NotifyContext.Provider
          value={ {
            addNotification: addNotification
          } }
        >
          {/* <RoutesMain />
          {showMasterLoading && (
            <Overlay>
              <Loader />
            </Overlay>
          )} */}
          <BrowserRouter basename={ process.env.REACT_APP_PUBLIC_URL }>
            <App />
          </BrowserRouter>
        </NotifyContext.Provider>
      </TitleContext.Provider>
    </>
  );
}

export default MasterComponent;
