import React, { useState } from 'react';

import { Button, TextField, Paper, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DatePicker } from '@material-ui/pickers';
import { useHistory } from 'react-router-dom';

import VirtualizedSelect from 'common/select';
import AutoSuggest from 'common/autosuggest';

import { getPublishers, freeIsbn } from 'services';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    margin: '4px 0 8px'
  },
  orders: {
    width: '100%'
  },
  actionBar: {
    marginTop: theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }
  },
}));

export const IsbnForm = React.memo(function IsbnForm({ isbn, onSubmit, submitButtonText, onChange, children, readonly }) {
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const dbGetPublishers = async () => {
    if (loading) return;
    setLoading(true);
    const response = await getPublishers();
    const result = response.data.reduce(
      (previousValue, currentValue) => {
        previousValue.push({
          value: currentValue.id,
          label: currentValue.name,
        });
        return previousValue;
      },
      [],
    );
    setOptions(result);
    setLoading(false);
  };

  const handleGetPublishers = () => {
    if (!options.length) dbGetPublishers();
  };

  const handleChangeSelect = (e) => {
    if (!e) return;
    onChange('idPublisher', e.value);
  };

  const handleChange = name => (e) => {
    onChange(name, e.target.value);
  };

  const handleGetSuggestions = async (value) => {
    try {
      const response = await freeIsbn(value);
      const result = response.data.isbns.map(el => ({ label: el }));
      return result;
    } catch (error) {
      return [];
    }
  };

  const handleChangeIsbn = name => (value) => {
    onChange(name, value);
  };

  const handleChangeDate = value => {
    onChange('createDate', value.format('YYYY-MM-DD'));
  };

  const openPublisher = () => {
    history.push(`/publisher/edit/${isbn.idPublisher}`)
  };

  return (
    <form className={classes.container} onSubmit={onSubmit} noValidate>
      <Paper style={{ padding: 16 }}>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={12}>
            {!readonly && <VirtualizedSelect
              options={options}
              isLoading={loading}
              onFocus={handleGetPublishers}
              onChange={handleChangeSelect}
              placeholder="Выбор издательства"
              label="Выбор издательства"
            />}
            {readonly && <TextField
              id="publisher"
              label="Издательство"
              type="text"
              fullWidth
              value={isbn.publisher?.name || ''}
              disabled={readonly}
            />}
          </Grid>
          <Grid item xs={4}>
            {readonly ?
              <TextField
                id="isbn"
                label="ISBN"
                type="text"
                fullWidth
                value={isbn.isbnCode}
                onChange={handleChange('isbnCode')}
                disabled={readonly}
                required
              /> :
              <AutoSuggest
                label="Префикс"
                onChange={handleChangeIsbn('isbnCode')}
                onSuggestionsFetchRequested={handleGetSuggestions}
                value={isbn.isbnCode}
                required
              />
            }
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              label="Дата"
              id="date"
              openTo="year"
              value={isbn.createDate}
              onChange={handleChangeDate}
              format='DD/MM/YYYY'
              fullWidth
              required
              disabled={readonly}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="count"
              label="Количество"
              type="number"
              fullWidth
              value={isbn.lastNumber || 0}
              onChange={handleChange('lastNumber')}
              disabled={readonly}
              required
            />
          </Grid>
        </Grid>
      </Paper>

      <div className={classes.orders}>
        {children}
      </div>

      <Box className={classes.actionBar}>
        {onSubmit &&
          <Button
            type="submit"
            variant="contained"
            className={classes.button}
            disabled={
              !isbn.isbnCode || !isbn.createDate || !isbn.lastNumber || !isbn.idPublisher
            }
          >
            {submitButtonText}
          </Button>
        }

        {
          !onSubmit &&
          <Button variant="outlined" onClick={openPublisher} color="primary"
            className={classes.btnOpenPublisher}
          >
            Открыть издателя
          </Button>
        }

      </Box>

    </form>
  );
});
